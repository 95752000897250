import { format, isToday, isValid, isYesterday } from "date-fns";

export const humanizeTime = (date: string) => {
  if (!date) {
    return null;
  }

  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    return null;
  }

  if (isToday(parsedDate)) {
    return `Today at ${format(parsedDate, "h:mm aa")}`;
  }

  if (isYesterday(parsedDate)) {
    return `Yesterday at ${format(parsedDate, "h:mm aa")}`;
  }

  return format(parsedDate, "MMM d, yyyy");
};

export const impactFrontBadgeUrls = {
  therapyHours: "/images/impact-badges/therapy-hours.png",
  lifeChangingOutcomes: "/images/impact-badges/life-changing-outcomes.png",
  helpedJourney: "/images/impact-badges/helped-journey.png",
  consistentTreatment: "/images/impact-badges/active-members.png",
};

export const impactBackBadgeUrls = {
  helpedJourney: "/images/impact-badges/back-helped-journey.png",
  therapyHours: "/images/impact-badges/back-therapy-hours.png",
  lifeChangingOutcomes: "/images/impact-badges/back-life-changing-outcomes.png",
  consistentTreatment: "/images/impact-badges/back-active-members.png",
};
